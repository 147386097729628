<template>
  <span class="badge" :class="className" :style="`font-size: ${size};`" >{{ text }}</span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    className: {
      type: String,
      required: false,
      default: 'badge-primary'
    },
    size: {
      type: String,
      required: false,
      default: '1em'
    }
  }
}
</script>
