<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">Paramètres Shopify</div>
          <div class="card-body">
            <dl class="row">
              <dt class="col-6">Nom de l'app</dt>
              <dd class="col-6">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" :value="appName">
                  <div class="input-group-append cursor" @click.prevent="copy(appName)">
                    <span class="input-group-text" id="basic-addon2">
                      <fa-icon :icon="['fas', 'copy']" size="sm"></fa-icon>
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-6">Domaine Shopify</dt>
              <dd class="col-6">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" :value="appDomain">
                  <div class="input-group-append cursor" @click.prevent="copy(appDomain)">
                    <span class="input-group-text" id="basic-addon2">
                      <fa-icon :icon="['fas', 'copy']" size="sm"></fa-icon>
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-6">App URL</dt>
              <dd class="col-6">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" :value="appUrl">
                  <div class="input-group-append cursor" @click.prevent="copy(appUrl)">
                    <span class="input-group-text" id="basic-addon2">
                      <fa-icon :icon="['fas', 'copy']" size="sm"></fa-icon>
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-6">Callback URL</dt>
              <dd class="col-6">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" :value="callBackUrl">
                  <div class="input-group-append cursor" @click.prevent="copy(callBackUrl)">
                    <span class="input-group-text" id="basic-addon2">
                      <fa-icon :icon="['fas', 'copy']" size="sm"></fa-icon>
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">Détail de la boutique externe</div>
          <div class="card-body">
            <dl class="row">
              <div class="col-12">
                <v-dynamic-form
                  :fields="fields"
                  :initial-values="initialForm"
                  @change="handleChange"
                  :errors="formErrors"
                ></v-dynamic-form>
              </div>
            </dl>
            <dl class="row">
              <div class="col-12">
                <button-loader :disabled="isSaving || isFormLocked" :loading="isSaving" @click="submit">
                  Enregistrer
                </button-loader>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import handleForm from '@/mixins/handle-form'
import useValidator from '@/mixins/useValidator'
import copy from '@/mixins/copy'
import ButtonLoader from '@/components/common/ButtonLoader'

export default {
  components: {
    ButtonLoader
  },
  props: {
    connectedStore: {
      type: Object,
      required: true
    }
  },
  mixins: [handleForm, useValidator, copy],
  data () {
    return {
      isSaving: false,
      form: {}
    }
  },
  computed: {
    appName () {
      return this.connectedStore ? `Lebonpanier - ${this.connectedStore.store.name}` : ''
    },
    appDomain () {
      if (this.connectedStore && this.connectedStore.data) {
        if (this.connectedStore.data.shop.includes('.myshopify.com')) {
          return this.connectedStore.data.shop
        }

        return `${this.connectedStore.data.shop}.myshopify.com`
      }

      return ''
    },
    appUrl () {
      return this.connectedStore ? `${config.app.boUrl}/connect/shopify/${this.connectedStore.id}` : ''
    },
    callBackUrl () {
      return this.connectedStore ? `${config.app.boUrl}/connect/shopify/${this.connectedStore.id}/callback` : ''
    },
    initialForm () {
      return this.connectedStore && this.connectedStore.data ? this.connectedStore.data : {}
    },
    isFormLocked () {
      return (!this.isConfigurable)
    },
    isConfigurable () {
      return this.connectedStore && ((!this.connectedStore.data) || (this.connectedStore.data && (!this.connectedStore.data.status || (this.connectedStore.data.status && this.connectedStore.data.status === 'initiated'))))
    },
    formErrors () {
      return this.errors
    },
    fields () {
      return [
        {
          name: 'installLink',
          label: "Lien d'installation",
          type: 'text',
          props: {
            disabled: this.isFormLocked
          }
        },
        {
          name: 'apiKey',
          label: 'API Key',
          type: 'text',
          props: {
            disabled: this.isFormLocked
          }
        },
        {
          name: 'apiSecretKey',
          label: 'API Secret Key',
          type: 'text',
          props: {
            disabled: this.isFormLocked
          }
        }
      ]
    },
    validationRules () {
      return {
        installLink: `string|url|regex:/${this.connectedStore.provider}/`,
        apiKey: 'string',
        apiSecretKey: 'string'
      }
    }
  },
  methods: {
    handleChange (values) {
      this.form = { ...values }
    },
    async submit () {
      if (this.isFormLocked) {
        return
      }

      this.isSaving = true

      try {
        this.errors = {}

        const validate = this.validate(this.form, this.validationRules)

        if (validate) {
          this.$emit('save', { id: this.$route.params.id, ...this.form })
          return
        }

        this.$toasted.error('Vous avez une/des erreur(s) dans le formulaire.')
      } catch (error) {
        this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
</style>
