<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Détail de la boutique externe</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <router-link class="btn btn-link btn-sm" to="/connected-stores" active-class="active">
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </router-link>
          </div>
        </div>
      </div>

      <async-object
        :should-fetch="true"
        :fetch-method="fetchConnectedStore"
      >
        <div v-if="connectedStore">
          <providers
            :connected-store="connectedStore"
          />
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import AsyncObject from '@/components/common/AsyncObject'
import Providers from '@/components/connected-stores/Providers'

export default {
  components: {
    AsyncObject,
    Providers
  },
  computed: {
    connectedStore () {
      return this.$store.getters['connectedStores/getDetailsById'](this.$route.params.id)
    }
  },
  methods: {
    fetchConnectedStore () {
      return this.$store.dispatch('connectedStores/fetchById', this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
</style>
