<template>
  <ul class="pagination">
    <li class="page-item" :class="{ disabled: !isPrevEnabled }">
      <a class="page-link" href="" aria-label="Previous" @click.prevent="handlePrev">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <template v-for="(page, i) in visiblePages">
      <li :key="`page-${i}`" class="page-item" :class="{ active: page === currentPage, disabled: page === '...' }">
        <a class="page-link" href="" @click.prevent="handlePage(page)">{{ page }}</a>
      </li>
    </template>
    <li class="page-item" :class="{ disabled: !isNextEnabled }">
      <a class="page-link" href="" aria-label="Next" @click.prevent="handleNext">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</template>

<script>
const getPageRange = (current, last, width = 2) => {
  const left = current - width
  const right = current + width
  const range = []
  const rangeWithDots = []
  let l

  for (let i = 1; i <= last; i += 1) {
    if (i === 1 || i === last || (i >= left && i <= right)) {
      range.push(i)
    } else if (i < left) {
      i = left - 1
    } else if (i > right) {
      range.push(last)
      break
    }
  }

  range.forEach((i) => {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  })

  return rangeWithDots
}

export default {
  props: {
    pages: {
      type: Number,
      required: false,
      default: 0
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    range: {
      type: Number,
      required: false,
      default: 3
    }
  },
  computed: {
    visiblePages () {
      return getPageRange(Number(this.currentPage), Number(this.pages), Number(this.range))
    },
    isPrevEnabled () {
      return Number(this.currentPage) > 1
    },
    isNextEnabled () {
      return Number(this.currentPage) < Number(this.pages)
    }
  },
  methods: {
    handlePage (page) {
      this.$emit('change', page)
    },
    handlePrev () {
      if (this.isPrevEnabled) {
        this.$emit('change', Number(this.currentPage) - 1)
      }
    },
    handleNext () {
      if (this.isNextEnabled) {
        this.$emit('change', Number(this.currentPage) + 1)
      }
    }
  }
}
</script>
