<template>
  <div>
    <span v-if="text">{{ text }}</span>
    <router-link v-if="haveLink && !useCallBackLink" class="btn btn-link btn-sm" :to="to" >
      {{ linkText }}
    </router-link>
    <a v-if="haveLink && useCallBackLink" class="btn btn-link btn-sm" @click.prevent="callBackLink(to)" >
      {{ linkText }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    haveLink: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    linkText: {
      type: String,
      required: false,
      default: ''
    },
    to: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    useCallBackLink: {
      type: Boolean,
      required: false,
      default: false
    },
    callBackLink: {
      type: Function,
      required: false,
      default: () => {
        return {}
      }
    }
  }
}
</script>
