import Vue from 'vue'

export default Vue.extend({
  methods: {
    copy (data: string) {
      navigator.clipboard.writeText(data)
      this.$toasted.success('Copié dans le clipboard.')
    }
  }
})
