<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Boutiques externes</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="form-control mobile-wid-100" @change="changePage(1)" v-model="perPage">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <input type="text" class="form-control quickfilter-input mobile-wid-100" placeholder="Filtrer par nom" @change="changePage(1)" v-model="textFilter">
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="custom-select mobile-wid-100" @change="changePage(1)" v-model="statusFilter">
                        <option value="">Statut</option>
                        <option value="invited">En attente vendeur</option>
                        <option value="initiated">En attente LBP</option>
                        <option value="ok">Complété</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-inline">
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1">
                      <a class="btn btn-sm btn-link" @click.prevent="syncData" >
                        <fa-icon class="fa-2x text-info" :icon="['fas', 'sync']"></fa-icon>
                      </a>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <a class="btn btn-sm btn-link" @click.prevent="reset()" >
                        <span class="fa-stack">
                          <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                          <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4 support-requests">
          <div class="card-body p-0">
            <div v-if="connectedStores.length === 0 && !isFetching" class="card-body text-center">
              <div class="py-3">Vous n'avez aucune boutique externe.</div>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="connectedStores"
              :show-actions="false"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              no-top-border
              hover
              @sortBy="handleSortBy"
            >
            </collection-table>

            <div v-if="paginatedData.pageCount > 1 && !isFetching" class="drawer-footer d-flex justify-content-center align-items-center">
              <pagination :current-page="page" :pages="paginatedData.pageCount" :range="2" @change="changePage" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import LinkTo from '@/components/common/LinkTo'
import Badge from '@/components/common/Badge'

export default {
  components: {
    Pagination
  },
  computed: {
    isLoading () {
      return this.$store.state.connectedStores.isLoading
    },
    isFetching () {
      return this.$store.state.connectedStores.isFetching
    },
    cols () {
      return [
        {
          header: 'Nom de la boutique',
          property: 'store.name',
          isSortable: true
        },
        {
          header: 'Provider',
          property: 'provider',
          isSortable: true
        },
        {
          header: 'Statut',
          property: 'status',
          component: Badge,
          props: (connectedStore) => {
            let text = 'En attente LBP'
            let className = 'badge-danger'

            if (connectedStore.data && connectedStore.data.status) {
              switch (connectedStore.data.status) {
                case 'invited':
                  text = 'En attente vendeur'
                  className = 'badge-warning'
                  break
                case 'ok':
                  text = 'Complété'
                  className = 'badge-success'
                  break
              }
            }

            return {
              text,
              className
            }
          }
        },
        {
          header: 'Action',
          property: 'id',
          component: LinkTo,
          props: (connectedStore) => {
            const isNotInitiated = ((connectedStore.data && connectedStore.data.status && connectedStore.data.status === 'initiated') || !connectedStore.data || (connectedStore.data && !connectedStore.data.status))

            return {
              haveLink: true,
              to: { name: 'connectedStores.details', params: { provider: connectedStore.provider, id: connectedStore.id } },
              linkText: isNotInitiated ? 'Configurer' : 'Voir'
            }
          }
        }
      ]
    },
    paginatedData () {
      return this.$store.state.connectedStores.paginatedData
    },
    connectedStores () {
      return this.paginatedData.data
    },
    textFilter: {
      get () {
        return this.$store.state.connectedStores.textFilter
      },
      set (value) {
        this.$store.commit('connectedStores/SET_TEXT_FILTER', value)
      }
    },
    statusFilter: {
      get () {
        return this.$store.state.connectedStores.statusFilter
      },
      set (value) {
        this.$store.commit('connectedStores/SET_STATUS_FILTER', value)
      }
    },
    sortDirection: {
      get () {
        return this.$store.state.connectedStores.sortDirection
      },
      set (value) {
        this.$store.commit('connectedStores/SET_SORT_DIRECTION', value)
      }
    },
    sortBy: {
      get () {
        return this.$store.state.connectedStores.sortBy
      },
      set (value) {
        this.$store.commit('connectedStores/SET_SORT', value)
      }
    },
    perPage: {
      get () {
        return this.$store.state.connectedStores.perPage
      },
      set (value) {
        this.$store.commit('connectedStores/SET_PER_PAGE', value)
      }
    },
    page () {
      return this.$store.state.connectedStores.page
    }
  },
  methods: {
    async changePage (page) {
      await this.fetchConnectedStores(page)
    },
    async fetchConnectedStores (page) {
      await this.$store.dispatch('connectedStores/fetch', { page })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchConnectedStores()
    },
    async syncData () {
      await this.fetchConnectedStores(this.page)
      this.$toasted.info('Les produits sont actualisées')
    },
    reset () {
      this.textFilter = ''
      this.statusFilter = ''
      this.sortBy = ''
      this.sortDirection = 'DESC'

      this.fetchConnectedStores()
    }
  },
  created () {
    this.fetchConnectedStores()
  }
}
</script>

<style lang="scss">
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
</style>
