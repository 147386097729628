<template>
  <div>
    <shopify
      v-if="connectedStore.provider === 'shopify'"
      :connected-store="connectedStore"
      @save="save"
    />
    <div v-else>
      Une erreur interne s'est produite. Le provideur est invalide.
    </div>
  </div>
</template>

<script>
import Shopify from './providers/Shopify'

export default {
  components: {
    Shopify
  },
  props: {
    connectedStore: {
      type: Object,
      required: true
    }
  },
  methods: {
    async save (body) {
      await this.$store.dispatch('connectedStores/patchConnectedStore', body)
      this.$toasted.success('Boutique externe mis à jour avec succès.')
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
</style>
